import React from 'react'
import Layout from '../../components/layout'
import { StaticImage } from 'gatsby-plugin-image'
import * as styles from '../../pages/e-books.module.css'

const FramePage = () => {
  return (
    <Layout>
      <section className={`${styles.jumbo} jumbo h-52 sm:h-72`}>
        <div className="container">
          <h1 className="text-5xl sm:text-8xl text-red-800">Frame</h1>
        </div>
      </section>
      <section className="content pt-16">
        <div className="container mx-auto px-4 !max-w-[100%]">
          <div className="flex flex-col md:flex-row gap-12">
            <div className="w-full md:w-1/4">
              <StaticImage 
                src="../../images/frame-cover.jpg"
                alt="Frame Book Cover"
                className="w-full rounded-lg"
              />
            </div>
            <div className="w-full md:w-1/2">
              <div className="flex flex-col md:flex-row justify-between items-center gap-4 mb-4">
                <h2 className="text-3xl font-bold text-gray-900 !mt-0">The Complete Blueprint for Passing Female Tests</h2>
                <a 
                  href="https://marciancx.gumroad.com/l/frame"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="inline-block bg-red-800 text-white px-4 py-4 rounded-lg hover:bg-red-900 transition-colors whitespace-nowrap"
                >
                  Get your FREE copy!
                </a>
              </div>
              <div className="space-y-4">
                <p className="text-gray-700 text-base">
                  Women don't test men <strong>to be difficult</strong>—they test men to <strong>find out who they truly are.</strong>
                </p>
                <p className="text-gray-700 text-base">
                  If you've ever had a woman <strong>pick a fight out of nowhere, pull back when things were going well, or push your boundaries just to see if you'd hold them,</strong> you've been tested. Most men <strong>fail these tests without even realizing it</strong>—they react emotionally, chase validation, or fold under pressure. And once that happens, <strong>respect and attraction start to fade.</strong>
                </p>
                <p className="text-gray-700 text-base">
                  This guide gives you <strong>the exact blueprint to pass every test effortlessly.</strong> Inside, you'll learn:
                </p>
                <p className="text-gray-700 text-base">
                  • <strong>The five major tests women use to assess a man's strength</strong><br />
                  • <strong>How to pass with ease—without arguing, explaining, or chasing</strong><br />
                  • <strong>Why masculine frame is the key to respect, attraction, and long-term desire</strong><br />
                  • <strong>The mindsets that make tests irrelevant—so you stop "playing defense" and naturally command respect</strong>
                </p>
                <p className="text-gray-700 text-base mb-8">
                  Women follow <strong>men who are grounded, unshaken, and in control.</strong> If you want to be that man, this is your playbook.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default FramePage 